
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";

export default Vue.extend({
  name: "Allocation",

  props: {
    serviceType: {
      required: true,
      type: Number
    },
    order: {
      required: false,
      type: Object
    },
    fieldsAccess: {
      required: false,
      type: Object
    },
    isEdit: {
      required: false,
      type: Boolean
    }
  },

  data: () => ({
    rules,
    model: {} as any,
    selects: {
      subdivisions: [] as Array<SelectComponentInterface>,
      branches: [] as Array<SelectComponentInterface>,
      handlers: [] as Array<SelectComponentInterface>
    },
    allowedRoles: [
      "admin",
      "chairman",
      "vice_president",
      "subdivision_head",
      "branch_head",
      "direction_head",
      "dispatcher"
    ] as Array<string>
  }),

  watch: {
    serviceType: {
      immediate: true,
      handler() {
        this.loadBranches();
      }
    }
  },

  computed: {
    user(): any {
      return this.$store.getters["user/info"];
    },
    currentRole(): any {
      return this.$store.getters["user/currentRole"];
    },
    isSubdivisionHead(): any {
      return (
        this.$store.getters["user/currentRole"].alias === "subdivision_head"
      );
    },
    isExp(): any {
      return this.serviceType === 5;
    },
    hiddenExperts() {
      return this.isSubdivisionHead && this.isExp;
    },
    showSelectHandler() {
      return this.allowedRoles.includes(this.currentRole?.alias);
    }
  },

  mounted() {
    this.setDefaults();
    console.log(this.serviceType);
  },

  methods: {
    setDefaults(): void {
      if (this.order) {
        this.model.handler_id = this.order.handler_id;
        this.model.branch_id = this.order.branch_id;
        this.model.subdivision_id = this.order.subdivision_id;
      }
    },
    canEditField(key: string, field: string): boolean {
      if (!this.fieldsAccess || !Object.keys(this.fieldsAccess).length) {
        return true;
      }

      if (this.fieldsAccess[key]) {
        return true;
      }

      if (this.fieldsAccess[key].length) {
        return !this.fieldsAccess[key].includes(field);
      }
      return true;
    },

    async loadBranches(): Promise<void> {
      try {
        const response = await this.$API.branches().getList({
          filter: {
            service_types: this.serviceType
          }
        });

        this.selects.branches = [...response.items];

        if (!this.isEdit) {
          if (this.serviceType === 1 || this.serviceType === 3) {
            this.model.branch_id = response.default;
          } else {
            delete this.model.branch_id;
          }
        }

        if (
          !this.selects.branches.some(
            (item: any) => item.value === this.model.branch_id
          )
        ) {
          if (this.model.branch_id) {
            delete this.model.branch_id;
          }

          if (this.model.subdivision_id) {
            delete this.model.subdivision_id;
          }
        }

        if (this.selects.branches.length === 1) {
          const [branch] = this.selects.branches;
          this.model.branch_id = branch.value;
        }

        if (this.model.branch_id) {
          await this.loadSubdivisionsByBranch();
        } else {
          this.selects.subdivisions = [];
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadSubdivisionsByBranch(): Promise<any> {
      try {
        const response = await this.$API.subdivisions().getList({
          filter: {
            branches: this.model.branch_id,
            service_type: this.serviceType
          }
        });

        this.selects.subdivisions = [...response.items];

        if (!this.isEdit) {
          if (this.serviceType === 1 || this.serviceType === 3) {
            this.model.subdivision_id = response.default;
          } else {
            delete this.model.subdivision_id;
          }
        }

        if (this.selects.subdivisions.length === 1) {
          const [subdivision] = this.selects.subdivisions;
          this.model.subdivision_id = subdivision.value;
        }

        if (
          this.model.subdivision_id &&
          !this.selects.subdivisions.some(
            (el: any) => el.value === this.model.subdivision_id
          )
        ) {
          delete this.model.subdivision_id;
        }

        if (this.model.subdivision_id) {
          await this.loadSpecialists();
        }
        this.$emit("input", this.model);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadSpecialists(): Promise<void> {
      try {
        this.selects.handlers = await this.$API.users().getList({
          filter: {
            subdivision: this.model.subdivision_id,
            service_type: this.serviceType
          }
        });

        if (
          this.model.handler_id &&
          !this.selects.handlers.some(
            (item: any) => item.value === this.model.handler_id
          )
        ) {
          delete this.model.handler_id;
        }
        this.setAllocations();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    setAllocations(): void {
      this.$emit("input", this.model);
    }
  }
});
