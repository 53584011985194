
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import NestedSelect from "@/components/form-components/NestedSelect.vue";

export default Vue.extend({
  name: "Stock",

  components: {
    NestedSelect
  },

  props: {
    headers: {
      type: Array,
      required: false
    },
    items: {
      type: Array,
      required: false
    },
    errorMessages: {
      type: Object,
      required: false,
      default: () => ({})
    },
    language: {
      type: String,
      required: true,
      default: "ru"
    },
    serviceId: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data: () => ({
    rules,
    localItems: [] as Array<any>,
    selects: {
      categories: [] as Array<SelectComponentInterface>
    } as any,
    panel: [] as Array<number>,
    subcategories: {} as any,
    forceKey: 0 as number,
    toCancel: false as any,
    selectedProduct: null as any,
    model: { details: {} as any } as any
  }),

  watch: {
    items: {
      immediate: true,
      handler() {
        if (!Array.isArray(this.items)) {
          this.localItems = [];
        } else {
          this.localItems = this.items;
        }
      }
    },
    value: {
      immediate: true,
      handler() {
        this.model = this.value;
      }
    }
  },

  async mounted() {
    await this.loadData();
    await this.loadChildren();
  },

  methods: {
    async loadChildren(): Promise<void> {
      try {
        for (const item of this.localItems) {
          await this.loadChildrenList(item.category_id);
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadData(): Promise<void> {
      try {
        this.selects.categories = await this.$API
          .goodsCategories()
          .getParentsList();
        this.selects.units = await this.$API
          .measureUnits()
          .getList({ service_type_id: 5 });
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    async deleteRow(index: number): Promise<void> {
      this.localItems.splice(index, 1);
    },
    async loadChildrenList(id: number): Promise<void> {
      try {
        if (!id) return;

        this.subcategories[
          id
        ] = await this.$API.goodsCategories().getChildrenList(id);
        this.forceRender();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    forceRender(): void {
      this.forceKey = this.forceKey++;
    },
    addItem(): void {
      this.localItems.push({
        category_id: null,
        subcategory_id: null,
        code: "",
        name: "",
        quantity: "",
        measure_unit_id: null
      });
    },
    async submit(): Promise<void> {
      const form = this.$refs.form as Vue;
      if (form.validate()) {
        this.$emit("submit");

        if (this.toCancel) {
          await this.$router.push("/orders");
        }
      }
    },
    async search(value: string, key: string): Promise<any> {
      try {
        this.$forceUpdate();
        return await (this.$API as any)[key]().liveSearchCode(
          {
            search_field: "name",
            search_value: value,
            service_type_id: this.serviceId
          },
          { "x-localization": this.language }
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.forceRender();
    },
    async searchByCode(value: string, field: string): Promise<any> {
      try {
        this.$forceUpdate();
        return await this.$API.goods().liveSearchCode(
          {
            search_field: field,
            search_value: value,
            service_type_id: this.serviceId
          },
          { "x-localization": this.language }
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.forceRender();
    },
    setCode(event: any, index: number) {
      if (!this.localItems[index].code) {
        this.localItems[index].code = event.code;
      }
      this.localItems[index].name = event.name;
    },
    setName(event: any, index: number) {
      this.localItems[index].code = event.code;
      this.localItems[index].name = event.name;
      console.log(this.localItems)
    }
  }
});
